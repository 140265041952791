/* -----------------------------
 * BigFuture Fonts
 *  https://fonts.google.com/specimen/Unbounded
 * -----------------------------
*/

@use "sass:map";

@import "apricot-bf-variable";
@import "../mixins/font";
@import "../mixins/breakpoint";

/* Unbounded Fonts */
@include font-face-light(Unbounded, Unbounded-ExtraLight, 200, normal);
@include font-face-light(Unbounded, Unbounded-Light, 300, normal);
@include font-face-light(Unbounded, Unbounded-Regular, 400, normal);
@include font-face-light(Unbounded, Unbounded-Medium, 500, normal);
@include font-face-light(Unbounded, Unbounded-SemiBold, 600, normal);
@include font-face-light(Unbounded, Unbounded-Bold, 700, normal);
@include font-face-light(Unbounded, Unbounded-ExtraBold, 800, normal);
@include font-face-light(Unbounded, Unbounded-Black, 900, normal);

/* Headings */
.cb-bf-project {
  @for $i from 1 through 6 {
    h#{$i},
    .cb-bf-h#{$i} {
      font-family: Unbounded !important;
      font-size: map.get(map.get($heading, h#{$i}), size);
      line-height: map.get(map.get($heading, h#{$i}), line);
    }
  }

  .cb-bf-h1-lite {
    font-family: Unbounded !important;
    font-size: 1.5rem;
    line-height: 1.3333em;
  }

  /* sm, ms */
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    h1,
    .cb-bf-h1 {
      /* 40 */
      font-size: 2.5rem;

      /* 48 */
      line-height: 1.2em;
    }

    .cb-bf-h1-lite {
      /* 32 */
      font-size: 2rem;

      /* 40 */
      line-height: 1.25em;
    }

    h2,
    .cb-bf-h2 {
      /* 24 */
      font-size: 1.5rem;

      /* 32 */
      line-height: 1.3333em;
    }

    h3,
    .cb-bf-h3 {
      /* 20 */
      font-size: 1.25rem;

      /* 28 */
      line-height: 1.4em;
    }

    h4,
    .cb-bf-h4 {
      /* 18 */
      font-size: 1.125rem;

      /* 24 */
      line-height: 1.3333em;
    }

    h5,
    .cb-bf-h5 {
      /* 16 */
      font-size: 1rem;

      /* 22 */
      line-height: 1.375em;
    }

    h6,
    .cb-bf-h6 {
      /* 14 */
      font-size: 0.875rem;

      /* 20 */
      line-height: 1.4286em;
    }
  }

  /* lg, xl, 2xl, 3xl */
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    h1,
    .cb-bf-h1 {
      /* 48 */
      font-size: 3rem;

      /* 56 */
      line-height: 1.1667em;
    }

    .cb-bf-h1-lite {
      /* 40 */
      font-size: 2.5rem;

      /* 48 */
      line-height: 1.2em;
    }

    h2,
    .cb-bf-h2 {
      /* 32 */
      font-size: 2rem;

      /* 40 */
      line-height: 1.25em;
    }

    h3,
    .cb-bf-h3 {
      /* 28 */
      font-size: 1.75rem;

      /* 36 */
      line-height: 1.2857em;
    }

    h4,
    .cb-bf-h4 {
      /* 24 */
      font-size: 1.5rem;

      /* 32 */
      line-height: 1.3333em;
    }

    h5,
    .cb-bf-h5 {
      /* 19 */
      font-size: 1.1875rem;

      /* 28 */
      line-height: 1.4737em;
    }

    h6,
    .cb-bf-h6 {
      /* 16 */
      font-size: 1rem;

      /* 24 */
      line-height: 1.5em;
    }
  }
}

/*  New Headings */
@for $i from 1 through 6 {
  .cb-bf-h#{$i}-imp {
    font-family: Unbounded !important;
    font-size: map.get(map.get($heading, h#{$i}), size);
    line-height: map.get(map.get($heading, h#{$i}), line);
  }
}

.cb-bf-h1-lite-imp {
  font-family: Unbounded !important;
  font-size: 1.5rem;
  line-height: 1.3333em;
}

/* sm. md */
@include media-breakpoint-up("sm", $grid-breakpoints) {
  .cb-bf-h1-imp {
    /* 40 */
    font-size: 2.5rem;

    /* 48 */
    line-height: 1.2em;
  }

  .cb-bf-h1-lite-imp {
    /* 32 */
    font-size: 2rem;

    /* 40 */
    line-height: 1.25em;
  }

  .cb-bf-h2-imp {
    /* 24 */
    font-size: 1.5rem;

    /* 32 */
    line-height: 1.3333em;
  }

  .cb-bf-h3-imp {
    /* 20 */
    font-size: 1.25rem;

    /* 28 */
    line-height: 1.4em;
  }

  .cb-bf-h4-imp {
    /* 18 */
    font-size: 1.125rem;

    /* 24 */
    line-height: 1.3333em;
  }

  .cb-bf-h5-imp {
    /* 16 */
    font-size: 1rem;

    /* 22 */
    line-height: 1.375em;
  }

  .cb-bf-h6-imp {
    /* 14 */
    font-size: 0.875rem;

    /* 20 */
    line-height: 1.4286em;
  }
}

/* lg, xl, 2xl, 3xl */
@include media-breakpoint-up("lg", $grid-breakpoints) {
  .cb-bf-h1-imp {
    /* 48 */
    font-size: 3rem;

    /* 56 */
    line-height: 1.1667em;
  }

  .cb-bf-h1-lite-imp {
    /* 40 */
    font-size: 2.5rem;

    /* 48 */
    line-height: 1.2em;
  }

  .cb-bf-h2-imp {
    /* 32 */
    font-size: 2rem;

    /* 40 */
    line-height: 1.25em;
  }

  .cb-bf-h3-imp {
    /* 28 */
    font-size: 1.75rem;

    /* 36 */
    line-height: 1.2857em;
  }

  .cb-bf-h4-imp {
    /* 24 */
    font-size: 1.5rem;

    /* 32 */
    line-height: 1.3333em;
  }

  .cb-bf-h5-imp {
    /* 19 */
    font-size: 1.1875rem;

    /* 28 */
    line-height: 1.4737em;
  }

  .cb-bf-h6-imp {
    /* 16 */
    font-size: 1rem;

    /* 24 */
    line-height: 1.5em;
  }
}

/* Unbounded Classes */
.cb-unbounded-extra-light {
  @include family(Unbounded);

  font-weight: 200;
}

.cb-unbounded-light {
  @include family(Unbounded);

  font-weight: 300;
}

.cb-unbounded {
  @include family(Unbounded);

  font-weight: 400;
}

.cb-unbounded-medium {
  @include family(Unbounded);

  font-weight: 500;
}

.cb-unbounded-semi-bold {
  @include family(Unbounded);

  font-weight: 600;
}

.cb-unbounded-bold {
  @include family(Unbounded);

  font-weight: 700;
}

.cb-unbounded-extra-bold {
  @include family(Unbounded);

  font-weight: 800;
}

.cb-unbounded-black {
  @include family(Unbounded);

  font-weight: 900;
}
