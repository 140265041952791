/*
 * BigFuture Hero Band
 */

@import "apricot-bf-variable";
@import "../mixins/breakpoint";

.cb-band {
  &.cb-band-hero {
    /* BF Specific Hero Band */
    &.cb-bf-band-hero {
      position: relative;

      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: cover;

      .cb-bf-band-hero-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 50%;

        transition: width $transition-time-s ease-in-out;

        &.cb-bf-band-hero-overlay-left {
          left: 0;
        }

        &.cb-bf-band-hero-overlay-right {
          left: 50%;
        }
      }

      .row {
        &:first-of-type {
          min-height: 192px;
        }
      }

      .cb-band-hero-content {
        padding: 32px 0;

        .cb-text-overline {
          margin-bottom: 16px;
        }

        .cb-band-hero-title {
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1.2em;

          + .cb-band-hero-desc {
            margin-top: 16px;
          }
        }

        .cb-band-hero-desc {
          font-size: 1.3125rem;
          font-weight: 400;
          line-height: 1.5238em;
        }

        .cb-btn-row {
          margin-top: 0;
        }
      }

      @include media-breakpoint-down("md", $grid-breakpoints) {
        .row {
          &:first-of-type {
            min-height: 192px;
          }
        }
      }

      @include media-breakpoint-only("md", $grid-breakpoints) {
        .cb-band-hero-content {
          .cb-band-hero-title {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.25em;
          }

          .cb-band-hero-desc {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5em;
          }
        }
      }

      @include media-breakpoint-only("sm", $grid-breakpoints) {
        .cb-band-hero-content {
          padding: 24px 0;

          .cb-text-overline {
            margin-bottom: 8px;
          }

          .cb-band-hero-title {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.25em;
          }

          .cb-band-hero-desc {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5em;
          }
        }
      }

      @include media-breakpoint-only("xs", $grid-breakpoints) {
        .cb-bf-band-hero-overlay {
          left: 0 !important;
          width: 100%;
          margin-top: 320px;
        }

        .cb-band-hero-content {
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-height: 120px;
          margin-top: 320px;
          padding: 24px 0;

          .cb-text-overline {
            margin-bottom: 8px;
          }

          .cb-band-hero-title {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.3333em;
            letter-spacing: unset;

            + .cb-band-hero-desc {
              margin-top: 8px;
            }
          }

          .cb-band-hero-desc {
            font-size: 1rem;
            line-height: 1.5em;
          }
        }
      }
    }

    /* Center aligned panel */
    &.cb-band-panel-image {
      &.cb-band-hero-bg {
        .row {
          &:first-of-type {
            min-height: 192px;
          }
        }

        .cb-band-hero-content {
          padding: 48px 0;

          .cb-text-overline {
            margin-bottom: 16px;
          }

          .cb-band-hero-title {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 1.2em;

            + .cb-band-hero-desc {
              margin-top: 16px;
            }
          }

          .cb-band-hero-desc {
            font-size: 1.3125rem;
            font-weight: 400;
            line-height: 1.5238em;
          }

          .cb-btn-row {
            margin-top: 0;
          }
        }

        @include media-breakpoint-down("md", $grid-breakpoints) {
          .row {
            &:first-of-type {
              min-height: 192px;
            }
          }
        }

        @include media-breakpoint-only("md", $grid-breakpoints) {
          .cb-band-hero-content {
            .cb-band-hero-title {
              font-size: 2rem;
              font-weight: 700;
              line-height: 1.25em;
            }

            .cb-band-hero-desc {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5em;
            }
          }
        }

        @include media-breakpoint-only("sm", $grid-breakpoints) {
          .cb-band-hero-content {
            padding: 24px 0;

            .cb-text-overline {
              margin-bottom: 8px;
            }

            .cb-band-hero-title {
              font-size: 2rem;
              font-weight: 700;
              line-height: 1.25em;
            }

            .cb-band-hero-desc {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5em;
            }
          }
        }

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          .row {
            &:first-of-type {
              min-height: 112px;
            }
          }

          .cb-band-hero-content {
            padding: 24px 0;

            .cb-text-overline {
              margin-bottom: 8px;
            }

            .cb-band-hero-title {
              font-size: 1.5rem;
              font-weight: 700;
              line-height: 1.3333em;
              letter-spacing: unset;

              + .cb-band-hero-desc {
                margin-top: 8px;
              }
            }

            .cb-band-hero-desc {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}