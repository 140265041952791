/*
 * BigFuture Colors
 */

@import "apricot-bf-variable";

@each $color, $map in $color-themes {
  @each $key, $value in $map {
    .#{"cb-bf-" + $color + "-" + $key} {
      background-color: $value;
    }
    .#{"cb-bf-" + $color + "-" + $key + "-color"} {
      color: $value;
    }
  }
}

/* Border */
.cb-bf-chartreuse-border {
  border-color: $chartreuse!important;
}

.cb-bf-orange-border {
  border-color: $orange!important;
}

.cb-bf-pink-border {
  border-color: $pink!important;
}
