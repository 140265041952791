/*
 * BigFuture Logo
 */

@import "apricot-bf-variable";
@import "../mixins/font";
@import "../mixins/glyph";

@include font-face("BF Icons Logo", cb-bf-icons-logo, null, normal, woff ttf);

.cb-bf-logo {
  @include apricot-font(BF Icons Logo);

  position: relative;
  display: inline-block;
  background-image: none !important;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.cb-bf-logo {
    &::before {
      content: "\e910";
    }
  }
}
