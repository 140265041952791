/*
 * BigFuture Utility
 */

@import "apricot-bf-variable";
@import "../mixins/breakpoint";

.cb-bf-highlight {
  background-color: $orange-tint1;
}

.cb-bf-highlight-orange {
  background-color: $orange-tint2;
}

.cb-bf-highlight-blue {
  background-color: $blue-tint1;
}

/* Local Navigation */
.cb-bf-project {
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    .cb-local-navigation {
      .cb-site-name {
        display: none!important;
      }
    }
  }

  @include media-breakpoint-only("xs", $grid-breakpoints) {
    .cb-local-navigation {
      .cb-site-name {
        a {
          display: none!important;
        }
      }
    }
  }
}

/* Announcement Panel */
.cb-bf-announcement-panel {
  .cb-bf-announcement-panel-text {
    margin-top: 12px;
    margin-bottom: 12px;

    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5em;

    a {
      color: $white;
      text-decoration: underline;

      &:visited {
        color: $white;
      }

      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }

    @media (min-width: 1px) and (max-width: 374.98px) {
      font-size: 0.75rem;
      line-height: 2em;
    }

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      font-size: 0.875rem;
    }
  }

  a{
      color: $black1;

      &:visited {
        color: $black1!important;
      }

      .cb-bf-announcement-panel-text {
        color: $black1;

        &:visited {
          color: $black1;
        }

        &:hover,
        &:focus {
          color: $black1;
        }
      }

    &.cb-white-color {
      color: $white;

      &:visited {
        color: $white!important;
      }

      .cb-bf-announcement-panel-text {
        color: $white;

        &:visited {
          color: $white;
        }

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }
}
