/*
 * BigFuture Illustartions
 */

@use "sass:math";
@use "sass:map";

@import "apricot-bf-variable";
@import "../mixins/font";
@import "../mixins/glyph";
@import "../mixins/breakpoint";

@include font-face("BF Illus", $illus-file, null, normal, woff ttf);

.cb-bf-illus {
  @include apricot-font(BF Illus);

  position: relative;

  display: inline-block;

  width: 1em;
  height: 1em;

  font-size: 4rem;
  line-height: 1em;

  border-radius: 50%;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    color: $white;
  }

  &::after {
    color: $black1;
  }

  @each $key, $map in $illus {
    &.cb-bf-illus-#{$key} {
      @include pseudo-content-important(map.get($map, "before"), map.get($map, "after"));
    }
  }
}


 /* ------------------------------------ NEW SIZE PATTERN */

@each $size in $illus-size {
  .cb-bf-illus {
    &.cb-bf-illus-#{$size} {
      font-size: #{$size}px;
    }
  }
}

 /* ------------------------------------ COLOR */

.cb-bf-illus {
  &.cb-bf-illus-orange {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjUuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkU1QjAwO30KPC9zdHlsZT4KPGcgaWQ9IkNJUkNMRVMiPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iMzIiIGN5PSIzMiIgcj0iMjQiLz4KPC9nPgo8L3N2Zz4K");
  }

  &.cb-bf-illus-blue {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjUuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMzI0REM3O30KPC9zdHlsZT4KPGcgaWQ9IkNJUkNMRVMiPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iMzIiIGN5PSIzMiIgcj0iMjQiLz4KPC9nPgo8L3N2Zz4K");
  }

  &.cb-bf-illus-blue-tint {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjUuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojQ0VEQkYzO30KPC9zdHlsZT4KPGcgaWQ9IkNJUkNMRVMiPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iMzIiIGN5PSIzMiIgcj0iMjQiLz4KPC9nPgo8L3N2Zz4K")
  }
}
